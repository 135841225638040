<template>
  <section
    class="positioner"
  >
    <section
      :class="{isLeft: settingsState.isLeft}"
      class="flex justify-between"
    >
      <section
        class="position"
        style="width: 350px;"
      />
      <section class="relative">
        <div :style="[transformCoef, positionTransform]">
          <arrow-animation
            style="position: absolute; width: 572px; height: 445px; bottom: 0; left: 0"
            position="bottom"
            :is-scaled="false"
            @mousedown.self="forwardStory"
            @mouseup.stop=""
            @touchstart.self="forwardStory"
            @touchend.stop=""
          />
          <mobile-button
            :glow="true"
            :type="landing ? 'landing':'take-off'"
            :is-scaled="false"
            @mousedown.self="forwardStory"
            @mouseup.stop=""
            @touchstart.self="forwardStory"
            @touchend.stop=""
          />
        </div>
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  MobileButton,
  ArrowAnimation,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'
import { tutorialManager } from '@powerplay/core-minigames'

export default defineComponent({
  name: 'TutorialTakeOff',
  components: {
    MobileButton,
    ArrowAnimation
  },
  mixins: [WindowAspect],
  props: {
    landing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      settingsState: 'GameSettingsState/getGameSettingsState'
    }),
    transformCoef() {

      return {
        transform: `scale(${this.scaleCoef}) translate(0, 0)`
      }

    },
    positionTransform() {

      if (this.settingsState.isLeft) {

        return {
          transformOrigin: 'bottom left'
        }

      }

      return {
        transformOrigin: 'bottom right'
      }

    }
  },
  methods: {
    forwardStory() {

      if (tutorialManager.getTypeWriting()) {

        this.$store.commit('TutorialCoreState/SET_TYPE_WRITER', false)
        tutorialManager.setTypeWriting(false)
        return

      }
      if ([6, 10].includes(tutorialManager.getActualSectionId())) {

        tutorialManager.nextSection()

      }

    }
  }
})
</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 3.5%;
  left: -2.5%;
  width: 100%;
}
.flex {
  display: flex;

  &.isLeft {
      flex-direction: row-reverse;
  }
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

</style>
