<template>
  <section
    class="positioner"
    @mousedown.stop=""
    @mouseup.stop=""
    @touchstart.stop=""
    @touchend.stop=""
  >
    <section
      :class="{isLeft: !settingsState.isLeft}"
      class="flex justify-between"
    >
      <section
        class="position"
        style="width: 350px;"
      />
      <section class="relative">
        <div :style="[transformCoef, positionTransform]">
          <arrow-animation
            style="position: absolute; width: 572px; height: 445px; bottom: 0; left: 0"
            position="bottom"
            :is-scaled="false"
          />
          <power-stick
            :only-horizontal="horizontal"
            :glow="true"
            :is-scaled="false"
            @mousedown.stop=""
            @mouseup.stop=""
            @touchstart.stop=""
            @touchend.stop=""
          />
        </div>
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  PowerStick,
  ArrowAnimation,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'TutorialJoystickButton',
  components: {
    PowerStick,
    ArrowAnimation
  },
  mixins: [WindowAspect],
  props: {
    horizontal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      settingsState: 'GameSettingsState/getGameSettingsState'
    }),
    transformCoef() {

      return {
        transform: `scale(${this.scaleCoef}) translate(0, 0)`
      }

    },
    positionTransform() {

      if (this.settingsState.isLeft) {

        return {
          transformOrigin: 'bottom right'
        }

      }

      return {
        transformOrigin: 'bottom left'
      }

    }
  }
})
</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;

  &.isLeft {
      flex-direction: row-reverse;
  }
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

</style>
