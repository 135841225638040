<template>
  <div
    v-if="startPhaseState.showPlayerInfo"
    class="positioner"
  >
    <player-info-avatar
      v-if="startPhaseState.showName"
      :texts="getTexts"
      :is-training="isTraining"
      :background-id="getAvatarBackgroundId"
      :background-ext="getAvatarBackgroundExt"
      :avatar-id="getAvatarId"
      :avatar-ext="getAvatarExt"
      :sex="getSex"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import {
  playersManager,
  trainingManager,
  modes,
  AvatarImageExtensions
} from '@powerplay/core-minigames'
import { PlayerInfoAvatar } from '@powerplay/core-minigames-ui'
import { endCalculationsManager } from '@/app/EndCalculationsManager'

export default defineComponent({
  name: 'PhaseStart',
  components: {
    PlayerInfoAvatar
  },
  computed: {
    ...mapGetters({
      startPhaseState: 'StartPhaseState/getStartPhaseState'
    }),
    getTexts() {

      let additionlInfo
      if (this.isTraining) {

        additionlInfo = trainingManager.bestScore.toString()

      } else {

        const distance = endCalculationsManager.getTargetDistanceToWin(false)
        additionlInfo = distance > 0 ? `${distance.toFixed(1)}m` : '-'

      }

      return {
        name: playersManager.players[0].name,
        country: playersManager.players[0].country.toLowerCase(),
        countryString: playersManager.players[0].countryString,
        additionlInfo,
        attempt: this.startPhaseState.attempt
      }

    },
    isTraining() {

      return modes.isTrainingMode()

    },
    getAvatarBackgroundId() {

      const avatarBg = playersManager.getPlayer().avatarBg
      return avatarBg === undefined ? 1 : Number(avatarBg)

    },
    getAvatarBackgroundExt() {

      // zatial takto, ak by do buducna trebalo, vieme doplnit funkcionalitu
      return AvatarImageExtensions.png

    },
    getAvatarId() {

      const avatar = playersManager.getPlayer().avatar
      return avatar === undefined ? 1 : Number(avatar)

    },
    getAvatarExt() {

      const playerInfo = playersManager.getPlayer()
      let avatarExt = playerInfo.avatarExt
      if (avatarExt === undefined) {

        avatarExt = playerInfo.avatar === 104 ?
          AvatarImageExtensions.gif :
          AvatarImageExtensions.png

      }
      return avatarExt

    },
    getSex() {

      return playersManager.getPlayer().sex

    }

  }
})
</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
}
</style>
