<template>
  <landing-box
    v-if="landingState.isActive"
    class="landing-box"
    :color="landingState.color"
    :text1="$t(landingState.text)"
    :text2="landingState.meters"
    :text3="$t(landingState.personalBestText)"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { LandingBox } from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'LandingComponent',
  components: {
    LandingBox
  },
  computed: {
    ...mapGetters({
      landingState: 'LandingState/getLandingState'
    })
  }
})
</script>

<style lang="less" scoped>
.landing-box {
  position: absolute;
  transform-origin: 0px 100%;
  left: 50%;
  bottom: 15%;
}
</style>
