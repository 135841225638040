<template>
  <traffic-light-box
    v-if="takeoffState.isActive"
    class="traffic-light-box"
    :color="takeoffState.color"
    :text="$t(takeoffState.text)"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { TrafficLightBox } from '@powerplay/core-minigames-ui'

export default defineComponent({
  name: 'TrafficComponent',
  components: {
    TrafficLightBox
  },
  computed: {
    ...mapGetters({
      takeoffState: 'TakeoffState/getTakeoffState'
    })
  }
})
</script>

<style lang="less" scoped>
.traffic-light-box{
    position: absolute;
    left: 50%;
    top: 5%;
}
</style>
