<template>
  <full-info
    v-if="showElement"
    class="information"
    :element-data="infoData"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { FullInfo } from '@powerplay/core-minigames-ui'
import { playersManager } from '@powerplay/core-minigames'
import { mapGetters } from 'vuex'
// import InformationState from '@/store/modules/informationState'

export default defineComponent({
  name: 'InformationSection',
  components: {
    FullInfo
  },
  computed: {
    ...mapGetters({
      showElement: 'InformationState/getShowState',
      informationState: 'InformationState/getInformationState'
    }),
    infoData: function() {

      return {
        opinionInfo: this.informationState.opinionInfo,
        playerObject: {
          points: this.informationState.points,
          length: this.informationState.meters,
          name: playersManager.players[0].name,
          position: playersManager.getPlayerActualPosition(),
          country: playersManager.players[0].country,
          countryString: playersManager.players[0].countryString,
          secondLength: this.informationState.secondLength
        },
        wind: this.informationState.wind
      }

    }
  }
})
</script>

<style lang="less" scoped>
.information {
    position: absolute;
    bottom: 20px;
    right: 1%;
    transform-origin: bottom right;
}
</style>
