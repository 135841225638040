<template>
  <div
    v-if="isBalanceActive"
    class="balance-positioning"
  >
    <horizontal-balance-bar
      :number-location="balanceStateValue"
      :style="{transformOrigin: 'bottom center'}"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { HorizontalBalanceBar } from '@powerplay/core-minigames-ui'

export default defineComponent({
  name: 'DescentBalanceBar',
  components: {
    HorizontalBalanceBar
  },
  computed: {
    ...mapGetters({
      isBalanceActive: 'DescentBalanceState/getBalanceActiveState',
      balanceStateValue: 'DescentBalanceState/getBalanceValue'
    })
  }
})
</script>

<style lang="less" scoped>
.balance-positioning {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%)
}
</style>
